import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import { Controller } from 'react-hook-form';

const FormItem = ({
  form,
  name,
  label,
  rules,
  wrapperProps,
  children: as,
  ...props
}) => (
  <Form.Item
    label={label}
    required={!!rules.required}
    validateStatus={form.errors[name] && 'error'}
    help={form.errors[name]?.message}
    {...wrapperProps}
  >
    <Controller
      as={as}
      name={name}
      control={form.control}
      rules={rules}
      defaultValue={form.getValues(name)}
      {...props}
    />
  </Form.Item>
);

FormItem.propTypes = {
  as: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  form: PropTypes.shape().isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  rules: PropTypes.shape(),
  wrapperProps: PropTypes.shape(),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};
FormItem.defaultProps = {
  label: undefined,
  rules: {},
  wrapperProps: {},
  children: Input,
};

export default FormItem;
