import React from 'react';
import PropTypes from 'prop-types';
import { Form, Select } from 'antd';
import { useForm } from 'react-hook-form';
import COUPON_STATUSES from '../constants/couponStatuses';
import { handleFinish, required } from '../services/form';
import FormItem from './FormItem';
import StatusTag from './StatusTag';
import VendorSelect from './VendorSelect';

const CouponForm = React.forwardRef(({ initialValues, onSubmit }, ref) => {
  const form = useForm({ defaultValues: initialValues });
  const { handleSubmit } = form;

  return (
    <Form
      ref={ref}
      layout="vertical"
      noValidate
      autoComplete="off"
      onFinish={handleFinish(handleSubmit(onSubmit))}
    >
      <FormItem
        form={form}
        name="id"
        label="Identifier"
        rules={{ required }}
        disabled
      />
      <FormItem form={form} name="user.id" label="Vendor" rules={{ required }}>
        <VendorSelect />
      </FormItem>
      <FormItem form={form} name="status" label="Status" rules={{ required }}>
        <Select optionLabelProp="label">
          {COUPON_STATUSES.map((status) => (
            <Select.Option key={status} label={status} value={status}>
              <StatusTag>{status}</StatusTag>
            </Select.Option>
          ))}
        </Select>
      </FormItem>
    </Form>
  );
});

CouponForm.propTypes = {
  initialValues: PropTypes.shape(),
  onSubmit: PropTypes.func.isRequired,
};
CouponForm.defaultProps = {
  initialValues: {},
};

export default CouponForm;
