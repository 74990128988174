import { pick, pickBy, truncate } from 'lodash';
import React, { useEffect, useState, useRef } from 'react';
import { Table, Tag, Button, Popconfirm, Modal, Space } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  MailOutlined,
  PhoneOutlined,
  EnvironmentOutlined,
  EuroCircleOutlined,
} from '@ant-design/icons';
import USER_ROLES, { VENDOR, ADMIN } from '../constants/userRoles';
import api from '../services/api';
import Page from './Page';
import DateTime from './DateTime';
import UserForm from './UserForm';

const ROLE_COLORS = {
  [VENDOR]: 'cyan',
  [ADMIN]: 'magenta',
};
const EMPTY_DETAIL = '—';

const UsersPage = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [users, setUsers] = useState();
  const formRef = useRef();

  useEffect(() => {
    (async () => {
      if (users) {
        return;
      }
      const { data } = await api.get('/users');
      setUsers(
        data.map((user) => ({
          ...user,
          ...(user.commission && {
            commissionType: user.commission.type,
            commissionAmount: user.commission.amount,
          }),
          key: user.id,
        })),
      );
    })();
  }, [users]);

  const openModal = (user) => {
    setModalOpen(true);
    setSelectedUser(user);
  };
  const closeModal = () => {
    setModalOpen(false);
    setSelectedUser();
  };

  const saveUser = async (user) => {
    const {
      id,
      commissionType,
      commissionAmount,
      contactPhone,
      contactEmail,
      address,
      companyDetails,
    } = user;
    user = pickBy(pick(user, ['name', 'email', 'password', 'role']));
    user.data = {
      commission: {
        type: commissionType,
        amount: commissionAmount,
      },
      contactPhone,
      contactEmail,
      address,
      companyDetails,
    };
    if (id) {
      await api.patch(`/users/${id}`, user);
    } else {
      await api.post('/users', user);
    }
    closeModal();
    setUsers();
  };

  const deleteUser = async (user) => {
    await api.delete(`/users/${user.id}`);
    setUsers();
  };

  return (
    <Page
      title="Users"
      actions={[
        <Button key="create" type="primary" onClick={() => openModal()}>
          <PlusCircleOutlined />
          Create
        </Button>,
      ]}
    >
      <Table
        columns={[
          {
            title: 'Name',
            dataIndex: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
            sortDirections: ['descend', 'ascend'],
          },
          {
            title: 'Email',
            dataIndex: 'email',
            sorter: (a, b) => a.email.localeCompare(b.email),
            sortDirections: ['descend', 'ascend'],
          },
          {
            title: 'Role',
            dataIndex: 'role',
            render: (value) => <Tag color={ROLE_COLORS[value]}>{value}</Tag>,
            filters: USER_ROLES.map((value) => ({ text: value, value })),
            onFilter: (value, user) => user.role === value,
            sorter: (a, b) => a.role.localeCompare(b.role),
            sortDirections: ['descend', 'ascend'],
          },
          {
            title: 'Details',
            render: (_, user) => {
              const { commission = {} } = user;
              const { type, amount } = commission;

              return user.role === VENDOR ? (
                <>
                  <Tag icon={<EuroCircleOutlined />}>
                    {type
                      ? `${amount}${type === 'percentage' ? '%' : ''}`
                      : EMPTY_DETAIL}
                  </Tag>
                  <Tag icon={<MailOutlined />}>
                    {user.contactEmail || EMPTY_DETAIL}
                  </Tag>
                  <Tag icon={<PhoneOutlined />}>
                    {user.contactPhone || EMPTY_DETAIL}
                  </Tag>
                  <Tag icon={<EnvironmentOutlined />}>
                    {user.address
                      ? truncate(user.address, { length: 10, omission: '…' })
                      : EMPTY_DETAIL}
                  </Tag>
                </>
              ) : (
                'N/A'
              );
            },
          },
          {
            title: 'Created',
            dataIndex: 'createdAt',
            width: 1,
            render: (value) => <DateTime>{value}</DateTime>,
            sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
            sortDirections: ['descend', 'ascend'],
          },
          {
            key: 'actions',
            width: 1,
            render: (_, user) => (
              <Space>
                <Button
                  size="small"
                  type="primary"
                  ghost
                  onClick={() => openModal(user)}
                >
                  <EditOutlined />
                  Edit
                </Button>
                <Popconfirm
                  title="Delete this user?"
                  okText="Yes"
                  okButtonProps={{ danger: true }}
                  cancelText="No"
                  onConfirm={() => deleteUser(user)}
                >
                  <Button size="small" danger>
                    <DeleteOutlined />
                    Delete
                  </Button>
                </Popconfirm>
              </Space>
            ),
          },
        ]}
        dataSource={users}
        loading={!users}
      />
      <Modal
        visible={modalOpen}
        destroyOnClose
        title={
          selectedUser ? `Edit User ‘${selectedUser.email}’` : 'Create User'
        }
        okText={selectedUser ? 'Save' : 'Create'}
        onOk={() => formRef.current.submit()}
        onCancel={closeModal}
      >
        <UserForm
          ref={formRef}
          initialValues={selectedUser || { role: VENDOR }}
          onSubmit={(user) => saveUser({ ...selectedUser, ...user })}
        />
      </Modal>
    </Page>
  );
};

export default UsersPage;
