import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import styles from './Logo.module.scss';

const Logo = ({ inverted }) => (
  <h1 className={cls(styles.root, inverted && styles.inverted)}>Arti namų</h1>
);

Logo.propTypes = {
  inverted: PropTypes.bool,
};
Logo.defaultProps = {
  inverted: false,
};

export default Logo;
