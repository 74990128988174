import React from 'react';
import { Avatar, Dropdown, Menu } from 'antd';
import {
  CaretDownOutlined,
  LogoutOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Link } from '@reach/router';
import useAuth from '../hooks/useAuth';
import styles from './UserButton.module.scss';

const UserButton = () => {
  const { token } = useAuth();
  return (
    <Dropdown
      trigger={['click']}
      overlay={
        <Menu>
          <Menu.Item key="logout">
            <Link to="/logout">
              <LogoutOutlined className={styles.icon} />
              Log out
            </Link>
          </Menu.Item>
        </Menu>
      }
    >
      <button className={styles.root}>
        <Avatar className={styles.avatar} icon={<UserOutlined />} />
        {token.email}
        <CaretDownOutlined className={styles.arrow} />
      </button>
    </Dropdown>
  );
};

export default UserButton;
