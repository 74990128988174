import React, { useState, useEffect } from 'react';
import { Table, Button, Space } from 'antd';
import { FileSearchOutlined } from '@ant-design/icons';
import { Link } from '@reach/router';
import config from '../config';
import { ADMIN } from '../constants/userRoles';
import api from '../services/api';
import useAuth from '../hooks/useAuth';
import Page from './Page';
import DateTime from './DateTime';

const AccountingPage = () => {
  const [data, setData] = useState();
  const { token } = useAuth();

  useEffect(() => {
    (async () => {
      const { data } = await api.get('/accounting');
      setData(data);
    })();
  }, []);

  return (
    <Page title="Accounting">
      <Table
        columns={[
          {
            title: 'Date',
            dataIndex: 'date',
            render: (value) =>
              value && <DateTime format="yyy-MM-dd">{value}</DateTime>,
          },
          token.role === ADMIN && {
            title: 'Vendor',
            dataIndex: 'user',
            render: (value) => (
              <Link to={`/users?name=${value.name}`}>{value.name}</Link>
            ),
            sorter: (a, b) => a.user.name.localeCompare(b.user.name),
            sortDirections: ['descend', 'ascend'],
          },
          {
            key: 'actions',
            title: 'View…',
            width: 1,
            render: (_, { date, user }) => {
              const getButtonProps = (type) => ({
                size: 'small',
                href: `${config.couponsUrl}/${type}/${user.id}/${date}`,
                target: '_blank',
              });
              return (
                <Space>
                  <Button {...getButtonProps('aktas')}>
                    <FileSearchOutlined />
                    Operations Act
                  </Button>
                  <Button {...getButtonProps('saskaita')}>
                    <FileSearchOutlined />
                    Commissions Invoice
                  </Button>
                </Space>
              );
            },
          },
        ]}
        dataSource={data}
        loading={!data}
      />
    </Page>
  );
};

export default AccountingPage;
