import React from 'react';
import PropTypes from 'prop-types';
import { trim } from 'lodash';
import { format as formatDate } from 'date-fns';
import styles from './DateTime.module.scss';

const DateTime = ({ format, children }) => (
  <span className={styles.root}>
    {formatDate(new Date(trim(children)), format)}
  </span>
);

DateTime.propTypes = {
  format: PropTypes.string,
  children: PropTypes.string.isRequired,
};
DateTime.defaultProps = {
  format: 'yyy-MM-dd HH:mm',
};

export default DateTime;
