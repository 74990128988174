import { noop } from 'lodash';
import * as Sentry from '@sentry/browser';
import config from '../config';

function capture(type, thing, extra, callback = noop) {
  if (!config.sentryDsn) {
    return;
  }
  Sentry.withScope((scope) => {
    if (extra) {
      scope.setExtra('extra', extra);
    }
    Sentry[type === 'error' ? 'captureException' : 'captureMessage'](thing);
    callback();
  });
}

export function captureError(error, extra) {
  capture('error', error, extra, () => {
    console?.error(error); // eslint-disable-line no-unused-expressions
  });
}

export function captureMessage(message, extra) {
  capture('message', message, extra);
}
