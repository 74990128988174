import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import config from './config';
import Root from './components/Root';

if (config.sentryDsn) {
  Sentry.init({
    dsn: config.sentryDsn,
  });
}

ReactDOM.render(<Root />, document.getElementById('root'));
