import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Space, Tag } from 'antd';
import {
  FilePdfOutlined,
  MailOutlined,
  PhoneOutlined,
  QrcodeOutlined,
} from '@ant-design/icons';
import { useForm, Controller } from 'react-hook-form';
import { handleFinish, required } from '../services/form';
import styles from './ValidateCouponForm.module.scss';
import FormItem from './FormItem';
import StatusTag from './StatusTag';
import config from '../config';

const ValidateCouponForm = React.forwardRef(
  ({ initialValues, onSubmit }, ref) => {
    const form = useForm({ defaultValues: initialValues });
    const { handleSubmit, control } = form;

    return (
      <Form
        ref={ref}
        layout="vertical"
        initialValues={initialValues}
        noValidate
        autoComplete="off"
        onFinish={handleFinish(handleSubmit(onSubmit))}
      >
        <FormItem
          form={form}
          name="id"
          label="Full Identifier"
          rules={{ required }}
          size="large"
          prefix={<QrcodeOutlined />}
          maxLength={16}
          {...(initialValues.status && {
            disabled: true,
            wrapperProps: {
              hasFeedback: true,
              validateStatus: 'success',
            },
          })}
        />
        {initialValues.status && (
          <>
            <Form.Item wrapperCol={{ className: styles.readOnly }}>
              <Button
                href={`${config.couponsUrl}/kuponas/${initialValues.id}`}
                target="_blank"
              >
                <FilePdfOutlined />
                View
              </Button>
            </Form.Item>
            <Form.Item
              label="Customer"
              wrapperCol={{ className: styles.readOnly }}
            >
              <Space>
                {initialValues.customer.name}
                {initialValues.customer.email && (
                  <Tag icon={<MailOutlined />}>
                    <a href={`mailto:${initialValues.customer.email}`}>
                      {initialValues.customer.email}
                    </a>
                  </Tag>
                )}
                {initialValues.customer.phone && (
                  <Tag icon={<PhoneOutlined />}>
                    <a href={`tel:${initialValues.customer.phone}`}>
                      {initialValues.customer.phone}
                    </a>
                  </Tag>
                )}
              </Space>
            </Form.Item>
            <Form.Item
              label="Price"
              wrapperCol={{ className: styles.readOnly }}
            >
              {initialValues.price}
            </Form.Item>
            <Form.Item
              label="Current Status"
              wrapperCol={{ className: styles.readOnly }}
            >
              <Controller
                as={<input type="hidden" />}
                name="status"
                control={control}
                defaultValue={initialValues.status}
              />
              <StatusTag>{initialValues.status}</StatusTag>
            </Form.Item>
          </>
        )}
      </Form>
    );
  },
);

ValidateCouponForm.propTypes = {
  initialValues: PropTypes.shape(),
  onSubmit: PropTypes.func.isRequired,
};
ValidateCouponForm.defaultProps = {
  initialValues: {},
};

export default ValidateCouponForm;
