import React from 'react';
import PropTypes from 'prop-types';
import { Layout, PageHeader } from 'antd';
import Logo from './Logo';
import Nav from './Nav';
import styles from './Page.module.scss';
import UserButton from './UserButton';

const { Sider, Header, Content, Footer } = Layout;

const Page = ({ title, subtitle, actions, children }) => (
  <Layout className={styles.root}>
    <Sider>
      <div className={styles.logo}>
        <Logo inverted />
      </div>
      <Nav />
    </Sider>
    <Layout>
      <Header className={styles.header}>
        <UserButton />
      </Header>
      <PageHeader title={title} subTitle={subtitle} extra={actions} />
      <Content className={styles.content}>{children}</Content>
      <Footer className={styles.footer}>
        © {new Date().getFullYear()} UAB Oro Guru. Visos teisės saugomos
      </Footer>
    </Layout>
  </Layout>
);

Page.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.shape()),
  children: PropTypes.node.isRequired,
};
Page.defaultProps = {
  subtitle: undefined,
  actions: [],
};

export default Page;
