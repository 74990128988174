import { filter, sortBy } from 'lodash';
import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import { VENDOR } from '../constants/userRoles';
import api from '../services/api';

const VendorSelect = (props) => {
  const [fetching, setFetching] = useState(true);
  const [vendors, setVendors] = useState([]);

  useEffect(() => {
    (async () => {
      setFetching(true);

      try {
        const { data } = await api.get('/users');
        setVendors(sortBy(filter(data, { role: VENDOR }), 'name'));
      } finally {
        setFetching(false);
      }
    })();
  }, []);

  return (
    <Select
      showSearch
      placeholder="Select a vendor"
      loading={fetching}
      disabled={fetching}
      filterOption={(input, option) =>
        option.children.toLocaleLowerCase().includes(input.toLocaleLowerCase())
      }
      {...props}
    >
      {vendors.map((vendor) => (
        <Select.Option key={vendor.id} value={vendor.id}>
          {vendor.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default VendorSelect;
