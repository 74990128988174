import { isString } from 'lodash';
import React from 'react';
import axios from 'axios';
import { Modal } from 'antd';
import config from '../config';
import store from './store';

const client = axios.create({
  baseURL: config.apiUrl,
  timeout: 60 * 1000,
});

client.interceptors.request.use((config) => {
  const accessToken = store.get('accessToken');
  if (accessToken) {
    config.headers = { Authorization: `Bearer ${accessToken}` };
  }
  return config;
});

client.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status !== 401) {
      Modal.error({
        title: error.message,
        content: isString(error.response.data) ? (
          error.response.data
        ) : (
          <pre style={{ margin: 0, fontSize: 10, color: '#ff4d4f' }}>
            {JSON.stringify(error.response.data, null, 2)}
          </pre>
        ),
      });
    }
    throw error;
  },
);

export default client;
