import { upperFirst } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { Form, Select, Input, Card } from 'antd';
import { useForm } from 'react-hook-form';
import USER_ROLES, { VENDOR } from '../constants/userRoles';
import COMMISSION_TYPES from '../constants/commissionTypes';
import {
  handleFinish,
  required,
  email,
  phone,
  minLength,
  min,
  max,
  number,
} from '../services/form';
import FormItem from './FormItem';

const UserForm = React.forwardRef(({ initialValues, onSubmit }, ref) => {
  const form = useForm({ defaultValues: initialValues });
  const { handleSubmit, watch } = form;

  const role = watch('role');

  return (
    <Form
      ref={ref}
      layout="vertical"
      noValidate
      autoComplete="off"
      onFinish={handleFinish(handleSubmit(onSubmit))}
    >
      <FormItem form={form} name="name" label="Name" rules={{ required }} />
      <FormItem
        form={form}
        name="email"
        label="Email"
        rules={{ required, pattern: email }}
        type="email"
      />
      <FormItem
        form={form}
        name="password"
        label="Password"
        rules={{
          ...(!initialValues.id && { required }),
          minLength: minLength(8),
        }}
        type="password"
      />
      <FormItem form={form} name="role" label="Role" rules={{ required }}>
        <Select>
          {USER_ROLES.map((role) => (
            <Select.Option key={role} value={role}>
              {upperFirst(role)}
            </Select.Option>
          ))}
        </Select>
      </FormItem>
      {role === VENDOR && (
        <Card
          size="small"
          title="Vendor details"
          style={{ background: '#fcfcfc' }}
        >
          <FormItem
            form={form}
            name="commissionType"
            label="Commission Type"
            rules={{ required }}
          >
            <Select>
              {COMMISSION_TYPES.map((type) => (
                <Select.Option key={type} value={type}>
                  {upperFirst(type)}
                </Select.Option>
              ))}
            </Select>
          </FormItem>
          <FormItem
            form={form}
            name="commissionAmount"
            label="Commission Amount"
            rules={{ required, pattern: number, min: min(0), max: max(100) }}
          />
          <FormItem
            form={form}
            name="contactEmail"
            label="Contact Email"
            rules={{ required, pattern: email }}
            type="email"
          />
          <FormItem
            form={form}
            name="contactPhone"
            label="Contact Phone"
            rules={{ required, pattern: phone }}
            type="phone"
          />
          <FormItem
            form={form}
            name="address"
            label="Address"
            rules={{ required }}
          />
          <FormItem
            form={form}
            name="companyDetails"
            label="Company Details (for invoicing)"
            rules={{ required }}
          >
            <Input.TextArea rows={5} />
          </FormItem>
        </Card>
      )}
    </Form>
  );
});

UserForm.propTypes = {
  initialValues: PropTypes.shape(),
  onSubmit: PropTypes.func.isRequired,
};
UserForm.defaultProps = {
  initialValues: {},
};

export default UserForm;
