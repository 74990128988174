import React, { useEffect } from 'react';
import { Layout, Modal } from 'antd';
import { Redirect } from '@reach/router';
import useAuth from '../hooks/useAuth';
import Logo from './Logo';
import LoginForm from './LoginForm';
import styles from './LoginPage.module.scss';

const LoginPage = () => {
  const { token, unauthorized, logIn } = useAuth();

  useEffect(() => {
    if (unauthorized) {
      Modal.error({
        title: 'Invalid credentials',
        content: 'Provided account details are incorrect.',
      });
    }
  }, [unauthorized]);

  return (
    <Layout className={styles.root}>
      <div className={styles.logo}>
        <Logo />
      </div>
      <LoginForm onSubmit={(values) => logIn(values.email, values.password)} />
      {token && <Redirect from="/login" to="/" noThrow />}
    </Layout>
  );
};

export default LoginPage;
