import React from 'react';
import PropTypes from 'prop-types';

const ExternalLink = ({ href, children, ...props }) => (
  <a href={href} rel="nofollow noopener noreferrer" target="_blank" {...props}>
    {children}
  </a>
);

ExternalLink.propTypes = {
  href: PropTypes.string,
  children: PropTypes.string.isRequired,
};
ExternalLink.defaultProps = {
  href: undefined,
};

export default ExternalLink;
