import { useEffect } from 'react';
import useAuth from '../hooks/useAuth';

const LogoutPage = () => {
  const { token, logOut } = useAuth();

  useEffect(() => {
    if (!token) {
      return;
    }
    logOut();
  }, [token, logOut]);

  return null;
};

export default LogoutPage;
