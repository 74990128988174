import React from 'react';
import { LocationProvider } from '@reach/router';
import { AuthProvider } from '../hooks/useAuth';
import ErrorBoundary from './ErrorBoundary';
import App from './App';

const Root = () => (
  <ErrorBoundary>
    <LocationProvider>
      <AuthProvider>
        <App />
      </AuthProvider>
    </LocationProvider>
  </ErrorBoundary>
);

export default Root;
