import React from 'react';
import { Menu } from 'antd';
import {
  QrcodeOutlined,
  ContainerOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Link, useLocation } from '@reach/router';
import { ADMIN } from '../constants/userRoles';
import useAuth from '../hooks/useAuth';
import styles from './Nav.module.scss';

const LINKS = [
  {
    path: '/coupons',
    label: 'Coupons',
    Icon: QrcodeOutlined,
  },
  {
    path: '/accounting',
    label: 'Accounting',
    Icon: ContainerOutlined,
    role: ADMIN,
  },
  {
    path: '/users',
    label: 'Users',
    Icon: UserOutlined,
    role: ADMIN,
  },
];

const Nav = () => {
  const location = useLocation();
  const { token } = useAuth();

  return (
    <Menu theme="dark" mode="inline" selectedKeys={[location.pathname]}>
      {LINKS.filter(({ role }) => !role || role === token.role).map(
        ({ path, label, Icon }) => (
          <Menu.Item key={path}>
            <Link className={styles.link} to={path}>
              <Icon />
              {label}
            </Link>
          </Menu.Item>
        ),
      )}
    </Menu>
  );
};

export default Nav;
