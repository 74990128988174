import React, { useState, useEffect, useContext, useCallback } from 'react';
import api from '../services/api';
import store from '../services/store';

const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState();
  const [pending, setPending] = useState(true);
  const [unauthorized, setUnauthorized] = useState();

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get('/session');
        setToken(data);
      } catch (err) {
        // ignore
      } finally {
        setPending(false);
      }
    })();
  }, []);

  const logIn = useCallback(async (email, password) => {
    try {
      const {
        data: { accessToken, ...token },
      } = await api.post('/session', { email, password });
      // An actual access token is saved to local storage only when the server returns it (NOT on production)
      if (accessToken) {
        store.set('accessToken', accessToken);
      }
      setToken(token);
    } catch (err) {
      if (err?.response.status === 401) {
        setUnauthorized(true);
        setTimeout(() => setUnauthorized(false));
        return;
      }
      throw err;
    }
  }, []);

  const logOut = useCallback(async () => {
    setPending(true);

    try {
      await api.delete('/session');
      store.remove('accessToken');
      setToken();
    } catch (err) {
      // ignore
    } finally {
      setPending(false);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{ token, pending, unauthorized, logIn, logOut }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default function useAuth() {
  return useContext(AuthContext);
}
