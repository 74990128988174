import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Result, Button } from 'antd';
import { captureError } from '../services/errors';

class ErrorBoundary extends Component {
  state = {
    errorMessage: undefined,
  };

  static getDerivedStateFromError(error) {
    return { errorMessage: error.message };
  }

  componentDidCatch(error, errorInfo) {
    captureError(error, errorInfo);
  }

  render() {
    const { children } = this.props;
    const { errorMessage } = this.state;

    return errorMessage ? (
      <Result
        status="500"
        title="Something went wrong :("
        subTitle="Sorry for the inconvenience. Our team has been notified about this error."
        extra={
          <Button type="primary" onClick={() => window.location.reload()}>
            Reload this page
          </Button>
        }
      />
    ) : (
      children
    );
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
