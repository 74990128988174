import { isObjectLike } from 'lodash';

const toKey = (key) => `artinamu:${key}`;

export default createInstance('local');

export function createInstance(type) {
  let storage = window[`${type}Storage`];
  if (!storage || !isStorageSupported(storage)) {
    storage = createMemoryStorage();
  }

  const get = (key) => {
    const value = storage.getItem(toKey(key));
    try {
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  };

  const set = (key, value) => {
    value = isObjectLike(value) ? JSON.stringify(value) : value;
    return storage.setItem(toKey(key), value);
  };

  const remove = (key) => storage.removeItem(toKey(key));

  const has = (key) => !!get(key);

  const getOnce = (key) => {
    const value = get(key);
    remove(key);
    return value;
  };

  return {
    get,
    set,
    remove,
    has,
    getOnce,
  };
}

function isStorageSupported(storage) {
  const testKey = 'local-storage-test';
  try {
    storage.setItem(testKey, testKey);
    const value = storage.getItem(testKey);
    storage.removeItem(testKey);
    return value === testKey;
  } catch (e) {
    return false;
  }
}

function createMemoryStorage() {
  const data = {};
  return {
    setItem: (key, value) => {
      data[key] = String(value);
    },
    getItem: (key) => data[key] || null,
    removeItem: (key) => delete data[key],
  };
}
