import { useEffect } from 'react';
import { useLocation, navigate } from '@reach/router';

const HomePage = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/coupons');
    }
  }, [location.pathname]);

  return null;
};

export default HomePage;
