import { noop } from 'lodash';

export const handleFinish = (handler) =>
  handler.bind(null, {
    persist: noop,
    preventDefault: noop,
  });

export const required = 'Cannot be empty';

export const email = {
  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  message: 'Must be a valid email address',
};

export const phone = {
  value: /^\+?\d{9,}$/,
  message: 'Must be a valid phone number',
};

export const number = {
  value: /^([1-9]\d*|0)(\.\d+)?$/,
  message: 'Must be a number',
};

export const minLength = (length) => ({
  value: length,
  message: `Must be at least ${length} characters`,
});

export const min = (min) => ({
  value: min,
  message: `Must be at least ${min}`,
});

export const max = (max) => ({
  value: max,
  message: `Must be no more than ${max}`,
});
