import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';
import { UNUSED, RESERVED, USED, VOIDED } from '../constants/couponStatuses';

const STATUS_COLORS = {
  [UNUSED]: '',
  [RESERVED]: 'blue',
  [USED]: 'green',
  [VOIDED]: 'red',
};

const StatusTag = ({ children }) => (
  <Tag color={STATUS_COLORS[children]}>{children}</Tag>
);

StatusTag.propTypes = {
  children: PropTypes.string.isRequired,
};

export default StatusTag;
