const isProd = process.env.NODE_ENV === 'production';

export default {
  apiUrl: isProd ? '/api' : 'http://localhost:3001',
  shopifyAdminUrl: 'https://artinamu.myshopify.com/admin',
  couponsUrl: 'https://kuponai.artinamu.lt',
  sentryDsn: isProd
    ? 'https://7dda158fa64b44238dfbe29c274ece37@o209395.ingest.sentry.io/5225610'
    : undefined,
};
