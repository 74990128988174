import React from 'react';
import { Router, Redirect } from '@reach/router';
import { Spin } from 'antd';
import 'antd/dist/antd.css';
import useAuth from '../hooks/useAuth';
import HomePage from './HomePage';
import CouponsPage from './CouponsPage';
import AccountingPage from './AccountingPage';
import UsersPage from './UsersPage';
import LoginPage from './LoginPage';
import LogoutPage from './LogoutPage';
import NotFoundPage from './NotFoundPage';
import styles from './App.module.scss';

const App = () => {
  const { pending, token } = useAuth();

  return pending ? (
    <div className={styles.loading}>
      <Spin size="large" />
    </div>
  ) : (
    <>
      <Router>
        {token && (
          <>
            <HomePage path="/" />
            <CouponsPage path="coupons" />
            <AccountingPage path="accounting" />
            <UsersPage path="users" />
            <LogoutPage path="logout" />
          </>
        )}
        <LoginPage path="login" />
        <NotFoundPage default />
      </Router>
      {!token && <Redirect to="/login" noThrow />}
    </>
  );
};

export default App;
