import { compact, size } from 'lodash';
import React, { useEffect, useState, useRef } from 'react';
import { Button, Modal, Table, Space } from 'antd';
import {
  CheckCircleOutlined,
  EditOutlined,
  FileDoneOutlined,
  FileSearchOutlined,
} from '@ant-design/icons';
import { Link } from '@reach/router';
import config from '../config';
import COUPON_STATUSES, {
  UNUSED,
  RESERVED,
  USED,
  VOIDED,
} from '../constants/couponStatuses';
import { VENDOR, ADMIN } from '../constants/userRoles';
import api from '../services/api';
import useAuth from '../hooks/useAuth';
import Page from './Page';
import DateTime from './DateTime';
import CouponForm from './CouponForm';
import ValidateCouponForm from './ValidateCouponForm';
import StatusTag from './StatusTag';
import ExternalLink from './ExternalLink';

const VALIDATE_MODAL = 'validate';
const EDIT_MODAL = 'edit';
const NEXT_COUPON_STATUS = {
  [UNUSED]: RESERVED,
  [RESERVED]: USED,
};

const CouponsPage = () => {
  const [openedModal, setOpenedModal] = useState();
  const [selectedCoupon, setSelectedCoupon] = useState();
  const [coupons, setCoupons] = useState();
  const formRef = useRef();
  const { token } = useAuth();

  useEffect(() => {
    (async () => {
      if (coupons) {
        return;
      }
      const { data } = await api.get('/coupons');
      setCoupons(data.map((coupon) => ({ ...coupon, key: coupon.id })));
    })();
  }, [coupons]);

  const openModal = (type) => setOpenedModal(type);
  const closeModal = () => {
    setOpenedModal();
    setSelectedCoupon();
  };

  const fetchCoupon = async (id) => {
    const { data } = await api.get(`/coupons/${id}`);
    setSelectedCoupon(data);
  };

  const markCouponAs = async (coupon, status) => {
    await api.post(`/coupons/${coupon.id}/mark/${status}`);
    closeModal();
    setCoupons();
  };

  const saveCoupon = async (coupon) => {
    await api.patch(`/coupons/${coupon.id}`, {
      userId: coupon.user.id,
      status: coupon.status,
    });
    closeModal();
    setCoupons();
  };

  const fillOrder = async () => {
    const orderId = window.prompt('Enter Shopify Order ID to fill:');
    if (!orderId) {
      return;
    }
    await api.post(`/coupons/create/${orderId}`);
    setCoupons();
  };

  return (
    <Page
      title="Coupons"
      actions={compact([
        token.role === VENDOR && (
          <Button
            key="validate"
            type="primary"
            onClick={() => openModal(VALIDATE_MODAL)}
          >
            <CheckCircleOutlined />
            Validate
          </Button>
        ),
        token.role === ADMIN && (
          <Button key="fill" type="primary" onClick={fillOrder}>
            <FileDoneOutlined />
            Fill Order
          </Button>
        ),
      ])}
    >
      <Table
        columns={compact([
          {
            title: 'Identifier',
            dataIndex: 'id',
            sorter: (a, b) => a.id.localeCompare(b.id),
            sortDirections: ['descend', 'ascend'],
          },
          token.role === ADMIN && {
            title: 'Order',
            dataIndex: 'orderName',
            render: (value, coupon) => (
              <ExternalLink
                href={`${config.shopifyAdminUrl}/orders/${coupon.orderId}`}
              >
                {value}
              </ExternalLink>
            ),
            sorter: (a, b) => a.orderId.localeCompare(b.orderId),
            sortDirections: ['descend', 'ascend'],
          },
          token.role === ADMIN && {
            title: 'Vendor',
            dataIndex: 'user',
            render: (value) => (
              <Link to={`/users?name=${value.name}`}>{value.name}</Link>
            ),
            sorter: (a, b) => a.user.name.localeCompare(b.user.name),
            sortDirections: ['descend', 'ascend'],
          },
          {
            title: 'Status',
            dataIndex: 'status',
            render: (value) => <StatusTag>{value}</StatusTag>,
            filters: COUPON_STATUSES.map((value) => ({ text: value, value })),
            onFilter: (value, coupon) => coupon.status === value,
            sorter: (a, b) => a.status.localeCompare(b.status),
            sortDirections: ['descend', 'ascend'],
          },
          {
            title: 'Updated',
            dataIndex: 'updatedAt',
            width: 1,
            render: (value) => value && <DateTime>{value}</DateTime>,
            sorter: (a, b) => {
              a = a.updatedAt || '';
              b = b.updatedAt || '';
              return a.localeCompare(b);
            },
            sortDirections: ['descend', 'ascend'],
          },
          {
            title: 'Created',
            dataIndex: 'createdAt',
            width: 1,
            render: (value) => <DateTime>{value}</DateTime>,
            sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
            sortDirections: ['descend', 'ascend'],
          },
          {
            key: 'actions',
            width: 1,
            render: (_, coupon) => (
              <Space>
                <Button
                  size="small"
                  disabled={token.role !== ADMIN && coupon.status === UNUSED}
                  onClick={async () => {
                    await fetchCoupon(coupon.id);
                    openModal(VALIDATE_MODAL);
                  }}
                >
                  <FileSearchOutlined />
                  View
                </Button>
                {token.role === ADMIN && (
                  <Button
                    size="small"
                    type="primary"
                    ghost
                    disabled={[USED, VOIDED].includes(coupon.status)}
                    onClick={() => {
                      setSelectedCoupon(coupon);
                      openModal(EDIT_MODAL);
                    }}
                  >
                    <EditOutlined />
                    Edit
                  </Button>
                )}
              </Space>
            ),
          },
        ])}
        dataSource={coupons}
        loading={!coupons}
      />
      <Modal
        visible={openedModal === VALIDATE_MODAL}
        destroyOnClose
        title="Validate Coupon"
        {...([USED, VOIDED].includes(selectedCoupon?.status)
          ? {
              okText: `Coupon is ${selectedCoupon?.status}`,
              okButtonProps: { disabled: true },
            }
          : {
              okText: selectedCoupon
                ? `Mark as ${NEXT_COUPON_STATUS[selectedCoupon.status]}`
                : 'Validate',
              onOk: () => formRef.current.submit(),
            })}
        onCancel={closeModal}
      >
        <ValidateCouponForm
          ref={formRef}
          initialValues={selectedCoupon}
          onSubmit={(coupon) => {
            if (size(coupon) === 1 && coupon.id) {
              fetchCoupon(coupon.id);
            } else if (coupon.status === UNUSED) {
              markCouponAs(coupon, RESERVED);
            } else if (coupon.status === RESERVED) {
              markCouponAs(coupon, USED);
            }
          }}
        />
      </Modal>
      <Modal
        visible={openedModal === EDIT_MODAL}
        destroyOnClose
        title={`Edit Coupon ‘${selectedCoupon?.id}’`}
        okText="Save"
        onOk={() => formRef.current.submit()}
        onCancel={closeModal}
      >
        <CouponForm
          ref={formRef}
          initialValues={selectedCoupon}
          onSubmit={saveCoupon}
        />
      </Modal>
    </Page>
  );
};

export default CouponsPage;
