import React from 'react';
import PropTypes from 'prop-types';
import { Form, Button } from 'antd';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { useForm } from 'react-hook-form';
import { handleFinish, required, email } from '../services/form';
import FormItem from './FormItem';
import styles from './LoginForm.module.scss';

const LoginForm = ({ onSubmit }) => {
  const form = useForm();
  const { handleSubmit } = form;

  return (
    <Form
      className={styles.root}
      noValidate
      autoComplete="off"
      onFinish={handleFinish(handleSubmit(onSubmit))}
    >
      <FormItem
        form={form}
        name="email"
        rules={{ required, pattern: email }}
        prefix={<MailOutlined />}
        type="email"
        placeholder="Email"
      />
      <FormItem
        form={form}
        name="password"
        rules={{ required }}
        prefix={<LockOutlined />}
        type="password"
        placeholder="Password"
      />
      <Form.Item>
        <Button type="primary" htmlType="submit" block>
          Log In
        </Button>
      </Form.Item>
    </Form>
  );
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default LoginForm;
